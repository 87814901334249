@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button, a {
  cursor: pointer;
}

* {
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.conteniner {
  max-width: 1440px;
  padding: 0 60px;
  margin: auto;
}
.header {
  padding: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-logo {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #0B132A;
  text-decoration: none;
}
.header-logo img {
  height: 36px;
  width: auto;
}
.reg-block {
  display: flex;
  gap: 16px;
}
.reg {
  color: #0BCCE3;
  border: 1px solid #0BCCE3;
  background: transparent;
  border-radius: 100px;
  padding: 8px 24px;
  font-size: 16px;  
  line-height: 18px;
  cursor: pointer;
  font-weight: 500;
}
.login {
  color: #0B132A;
  border: none;
  background: transparent;
  padding: 8px 24px;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 500;
}
.title-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 60px 200px;
  position: relative;
}
.title-text-block {
  max-width: 555px;
  flex: 1 1 50%;
}
.title-text-block h3 {
  font-size: 50px;
  line-height: 70px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 20px;
  color: #0B132A;
}
.title-text-block h3 span {
  font-weight: 600;
  color: #0BCCE3;
}
.title-text-block p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 20px;
  color: #4F5665;
}
.title-text-link {
  position: relative;
  width: 250px;
}
.title-text-link a,
.title-text-link button {
  text-decoration: none;
  font-weight: 600;
  color: #0BCCE3;
  width: 250px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #03E4FE;
  margin-top: 60px;
  color: #fff;
  line-height: 25px;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  z-index: 2;
  border: none;
}
.title-text-link button,
.title-text-link-modal {
  height: 50px;
  margin: 0;
  width: 100%;
}
.title-text-link::after {
  position: absolute;
  left: 0;
  top: 50%;
  content: '';
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(3, 228, 254, 0.25) 30%, transparent 70%);
}
.title-img-block,
.content-img-block {
  flex: 1 1 50%;
}
.title-img-block img,
.content-img-block img {
  width: 100%;
  height: auto;
}
.ststistic-block {
  width: calc(100% - 30px);
  position: absolute;
  height: 200px;
  background: #fff;
  bottom: 0;
  left: 30px;
  transform: translate(0, 50%);
  border-radius: 10px;
  box-shadow: 0 5px 25px rgba(13, 16, 37, 0.01), 0 5px 25px rgba(13, 16, 37, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}
.line {
  width: 2px;
  height: 126px;
  background: #EEEFF2;
}
.ststistic-item {
  flex: 0 0 calc((100% / 3) - 2px); 
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.ststistic-item-circle {
  background: #BCF8FF;
  border-radius: 100px;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #03E4FE;
}
.ststistic-item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ststistic-item-text h6 {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  margin: 0;
  color: #0B132A;
}

.ststistic-item-text p {
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  margin: 0;
  color: #4F5665;
}

.content-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px 60px 120px;
  position: relative;
}

.content-text-block {
  max-width: 450px;
  margin-left: 120px;
}
.content-text-block h4 {
  font-size: 35px;
  line-height: 50px;
  font-weight: 500;
  margin: 0 0 20px;
  color: #0B132A;
}
.content-text-block p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  margin: 0 0 20px;
  color: #4F5665;
}
.content-text-icon {
  color: #2FAB73;
  font-size: 24px;
}
.content-text-item {
 display: flex;
 justify-content: flex-start;
 align-items: center;
 gap: 8px;
 margin-top: 16px;
}
.content-text-item p {
  margin: 0;
  font-size: 14px;
  line-height: 30px;
  color: #4F5665;
}
.plan-block {
  padding: 100px 60px 200px;
  position: relative;
}
.plan-block::before {
  background: #FBFBFB;
  position: absolute;
  content: '';
  width: 100vw;
  height: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  z-index: -1;
}
.plan-block h3 {
  font-size: 35px;
  line-height: 50px;
  font-weight: 500;
  margin: 0 0 20px;
  color: #0B132A;
  text-align: center;
}
.plan-block-p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  margin: 0 0 20px;
  color: #4F5665;
  text-align: center;
  width: 530px;
  margin: auto;
}
.plan-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  margin-top: 60px;
}
.plan-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 40px;
  border: 2px solid #DDDDDD;
  border-radius: 10px;
  width: 330px;
}
.plan-item-blue {
  border: 2px solid #03E4FE;
}
.plan-item h5 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  margin: 0;
  color: #0B132A;
}
.plan-text-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.plan-text-item p {
  width: auto;
  font-size: 14px;
  line-height: 30px;
  color: #4F5665;
  font-weight: 400;
  margin: 0;
}
.plan-text-icon {
  color: #2FAB73;
  font-size: 16px;
}
.plan-item img {
  width: 150px;
  height: auto;
}
.plan-name {
  font-size: 25px;
  font-weight: 400;
  color: #4F5665;
  margin-top: 30px;
}
.plan-name span {
  font-weight: 600;
  color: #0B132A;
}
.plan-btn {
  color: #03E4FE;
  border: 1px solid #03E4FE;
  background: transparent;
  border-radius: 100px;
  text-decoration: none;
  width: 180px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
}
.plan-btn-blue {
  color: #fff;
  background: #03E4FE;
}

.plan-text-link {
  position: relative;
  width: 180px;
  cursor: pointer;
}
.plan-text-link::after {
  position: absolute;
  left: 0;
  top: 50%;
  content: '';
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(3, 228, 254, 0.25) 30%, transparent 70%);
  z-index: -1;
}
.download-block {
  width: calc(100% - 30px);
  position: absolute;
  height: 200px;
  background: #fff;
  bottom: 0;
  left: 30px;
  transform: translate(0, 50%);
  border-radius: 10px;
  box-shadow: 0 5px 25px rgba(13, 16, 37, 0.01), 0 5px 25px rgba(13, 16, 37, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 60px;
}
.download-block h3 {
  width: 620px;
  text-align: start;
  color: #0B132A;
  font-size: 35px;
  font-weight: 500;
  line-height: 45px;
}
.m-0 {
  margin: 0 !important;
}

.footer-block {
  padding: 160px 60px 60px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.footer-block::before {
  background: #F8F8F8;
  position: absolute;
  content: '';
  width: 100vw;
  height: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  z-index: -1;
}
.footer-right {
  display: flex;
  justify-content: center;
  align-items: self-start;
  gap: 60px;
}
.footer-right h6 {
  font-size: 18px;
  line-height: 30px;
  color: #0B132A;
  margin: 0;
  font-weight: 500;
}
.footer-right p {
  font-size: 16px;
  line-height: 30px;
  color: #4F5665;
  font-weight: 400;
  margin: 10px 0px;
}
.grey-text {
  color: #AFB5C0;
  font-size: 16px;
  font-weight: 400;
  margin-top: 40px;
}
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.modal-body {
  background: #fff;
  padding: 60px;
  border-radius: 10px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.modal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.modal-header h4 {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
}
.close {
  cursor: pointer;
  font-size: 20px;
  color: #0B132A;
}
.modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.modal-input {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #03E4FE;
}
.or-line {
  width: 100%;
  position: relative;
  height: 2px;
  background: #EEEFF2;
  margin: 20px 0;
}
.or-line p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 0 20px;
  color: #4F5665;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.modal-bottom-link {
  color: #4F5665;
  font-size: 14px;
  line-height: 20px;
}
.modal-bottom-link span {
  color: #03E4FE;
  cursor: pointer;
  font-weight: 600;
}
.google-button {
  border: 1px solid #0B132A;
  color: #0B132A;
  line-height: 25px;
  font-size: 16px;
  font-weight: 500;
  background: transparent;
  border-radius: 10px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.profile-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.profile-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  overflow: hidden;
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #03E4FE;
  color: #03E4FE;
  font-size: 20px;
}
.profile-logo img {
  width: auto;
  height: 100%;
}
.plan-status {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #03E4FE;
  color: #fff;
  line-height: 25px;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  z-index: 2;
  border: none;
  padding: 0 20px;
  height: 35px;
  text-transform: capitalize;

}
.profile-dropdown {
  position: absolute;
  top: 55px;
  right: 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 25px rgba(13, 16, 37, 0.01), 0 5px 25px rgba(13, 16, 37, 0.05);
  display: flex;
  flex-direction: column;
  width: 176px;
  z-index: 999;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 5px 1px 15px rgba(0, 0, 0, 0.3);
}
.dropdown-btn {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 16px 20px;
  background: #fff;
  width: 100%;
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.rotate {
  transform: rotate(180deg);
}
.red-text {
  color: #FF0000;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0px;
}
.sub-text {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0px;
  cursor: default;
}
.modal-text {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 20px;
  color: #4F5665;
  text-align: center;
}
.modal-btn-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-btn {
  flex: 0 0 45%;
  padding: 10px;
  border: 2px solid #03E4FE;
  border-radius: 10px;
  background-color: rgba(3, 228, 254, 0.1);
  color: #03E4FE;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.modal-btn-yes {
  color: #FF0000;
  background-color: rgba(255, 0, 0, 0.1);
  border: 2px solid #FF0000;
}
.footer-link {
  display: block;
  color: #4F5665;
  cursor: pointer;
  text-decoration: none;
  margin: 16px 0px;
}
.success-block {
  height: calc(100vh - 530px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.success-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}